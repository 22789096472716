<template>
  <div class="popup_wrap" style="width:900px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P020.001') }}</h1>
      <div class="content_box">
        <table class="tbl_row">
          <colgroup>
            <col width="120px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL020P020.003') }}</th>
              <td>
                <input type="text" class="wid200" v-model="searchKeyword" ref="searchKeywordInput" @keydown.enter="searchData">
              </td>
              <td class="border_left_none text_right">
                <a class="button blue sh" href="#" @click.prevent="searchData">{{ $t('cp.COMMON.001') }}</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="realgrid" class="mt10" style="width: 100%; height: 550px" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import FactorList from '@/api/rest/cp/factorList'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'factorCd', dataType: 'text' },
  { fieldName: 'factorNm', dataType: 'text' },
  { fieldName: 'factorType', dataType: 'text' },
  { fieldName: 'tbIdYn', dataType: 'text' },
  { fieldName: 'periodUseYn', dataType: 'text' },
  { fieldName: 'inputUseYn', dataType: 'text' },
  { fieldName: 'task', dataType: 'text' },
  { fieldName: 'refTable1', dataType: 'text' },
  { fieldName: 'refTable2', dataType: 'text' },
  { fieldName: 'refTable3', dataType: 'text' },
  { fieldName: 'refColumn1', dataType: 'text' },
  { fieldName: 'refColumn2', dataType: 'text' },
  { fieldName: 'refColumn3', dataType: 'text' },
  { fieldName: 'refColumn4', dataType: 'text' },
  { fieldName: 'refColumn5', dataType: 'text' },
  { fieldName: 'caseValue', dataType: 'text' },
  { fieldName: 'caseUnit', dataType: 'text' },
  { fieldName: 'remark', dataType: 'text' }
]

const columns = [
  { name: 'factorCd', fieldName: 'factorCd', header: { text: app.$t('cp.CTRL020P020.002') }, editable: false, width: 12 },
  { name: 'factorNm', fieldName: 'factorNm', header: { text: app.$t('cp.CTRL020P020.003') }, editable: false, width: 30, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'refTable1', fieldName: 'refTable1', header: { text: app.$t('cp.CTRL020P020.004') }, visible: false },
  { name: 'remark', fieldName: 'remark', header: { text: app.$t('cp.CTRL020P020.005') }, editable: false, width: 40, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'caseValue', fieldName: 'caseValue', header: { text: app.$t('cp.CTRL020P020.006') }, editable: false, width: 12 },
  { name: 'caseUnit', fieldName: 'caseUnit', header: { text: app.$t('cp.CTRL020P020.007') }, editable: false, width: 12 }
]

export default {
  name: 'TargetFactorListPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          position: '',
          factorCd: '',
          factorType: '',
          tbIdYn: '',
          factorCdC: '',
          factorTypeC: '',
          equlSignType: ''
        }
      }
    }
  },
  data: function () {
    return {
      list: [],
      searchKeyword: ''
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })
    gridView.setFilteringOptions({
        enabled: false,
        commitBeforeFiltering: true,
        handleVisibility: 'none',
        selector: {
          searchIgnoreCase: true
        }
      })

    gridView.onCellDblClicked = function (_grid, clickData) {
      const clickedRow = $vm.list[clickData.dataRow]
      if (($vm.parentInfo.factorCd === '' && $vm.parentInfo.factorCdC === '') ||
      (clickedRow.factorCd === 'FC000' && $vm.parentInfo.position.startsWith('R-'))) {
        // pass
      } else if ($vm.parentInfo.position.startsWith('L-') && $vm.parentInfo.factorTypeC !== '' && ($vm.parentInfo.factorTypeC !== clickedRow.factorType)) {
        $vm.openAlert($vm.$t('cp.CTRL020P020.008'))
        return
      } else if ($vm.parentInfo.position.startsWith('R-') && $vm.parentInfo.factorType !== '' && ($vm.parentInfo.factorType !== clickedRow.factorType)) {
        $vm.openAlert($vm.$t('cp.CTRL020P020.008'))
        return
      }

      // 일자 입력을 사용하지 않는 FACTOR의 경우 input tag 비우기
      let isClearPeriod = false
      if (clickedRow.periodUseYn === '' || clickedRow.periodUseYn === 'N') {
        isClearPeriod = true
      }

      $vm.$emit('selectFunc', { target: 'TargetFactorListPop', val: clickedRow })
      $vm.$emit('close')
      //$vm.$emit('close', clickedRow.factorCd, clickedRow.factorNm, clickedRow.refTable1, clickedRow.factorType, clickedRow.tbIdYn, clickedRow.periodUseYn, clickedRow.inputUseYn, isClearPeriod)
      //$vm.layerClose()
    }

    $vm.getData()
  },
  methods: {
    getData () {
      FactorList.factorList().then(response => {
        this.list = response.data
        provider.setRows(this.list)
        if (this.parentInfo.position.startsWith('L-') || this.parentInfo.equlSignType === 'ID') {
          const targetRow = provider.searchDataRow({ fields: ['factorCd'], values: ['FC000'] })
          if (targetRow > -1) {
            provider.removeRow(targetRow)
            this.list.splice(targetRow, 1)
          }
          this.list.forEach((item, index) => {
            if (this.parentInfo.factorCd === item.factorCd) {
              gridView.setCurrent({ itemIndex: index }, true)
            }
          })
        } else if (this.parentInfo.position.startsWith('R-')) {
          this.list.forEach((item2, index2) => {
            if (this.parentInfo.factorCdC === item2.factorCd) {
              gridView.setCurrent({ itemIndex: index2 }, true)
            }
          })
        } else {
          const targetRow = provider.searchDataRow({ fields: ['factorCd'], values: ['FC000'] })
          if (targetRow > -1) {
            provider.removeRow(targetRow)
            this.list.splice(targetRow, 1)
          }
          this.list.forEach((item, index) => {
            if (this.parentInfo.factorCd === item.factorCd) {
              gridView.setCurrent({ itemIndex: index }, true)
            }
          })
        }
        this.$refs.searchKeywordInput.focus()
      }).catch(err => {
        console.log(err)
      })
    },
    searchData () {
      this.$refs.searchKeywordInput.blur()
      const criteria = `(value like "%${this.searchKeyword}%")`
      const column = gridView.columnByName('factorNm')
      column.setFilters([{
        name: 'searchFilter',
        criteria: criteria,
        text: 'searchFilter',
        active: true
      }])
      this.$refs.searchKeywordInput.focus()
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    }
  }
}
</script>
